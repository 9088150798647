div {
  /* box-sizing: border-box; */
}
.App {
  text-align: center;
  display: flex;

  flex-direction: column;
}
.district-name-list {
  display: flex;
  flex-direction: row;
  width: 100%;
  color: #343434;
  padding: 5px;
}
.marker-name-list {
  width: 30%;
  color: #343434;
  padding: 5px;
}
.marker-name {
  display: inline-block;
  font-size: 20px;
  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;
  cursor: pointer;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.marker-name-wrap:nth-child(1) {
  text-align: center;
  font-weight: bold;
}
.marker-name-header {
  font-size: 24px;
  text-align: center;
  font-weight: bold;
}
.custom-kakao-map {
  height: 700px;
}
.marker-name:hover {
  background-color: #343434;
  color: #fff;
}
.marker-name-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
}
.marker-name-wrap:after {
  content: "";
  width: 1px;
  height: 20px;
  background-color: #343434;
  margin-left: 5px;
  margin-right: 5px;
}

.marker-name-wrap:nth-last-child(1):after {
  width: 0px;
  height: 0px;
  background-color: unset;
  margin-left: 0px;
  margin-right: 0px;
}

@media (max-width: 767px) {
  .custom-kakao-map {
    height: 500px;
  }
  .marker-name {
    vertical-align: middle;
    font-size: 16px;
    padding-left: 5px;
    padding-right: 5px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .marker-name:hover {
    background-color: #fff;
    color: #5085bb;
  }
  .marker-name::after {
    content: "";
    width: 1px;
    height: 20px;
    background-color: #343434;
  }
  .marker-name-wrap:after {
    content: "";
    width: 1px;
    height: 15px;
    background-color: #343434;
    margin-left: 3px;
    margin-right: 3px;
  }
}

.wrap {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 288px;
  height: 132px;
  margin-left: -144px;
  text-align: left;
  overflow: hidden;
  font-size: 12px;
  line-height: 1.5;
}
.wrap * {
  padding: 0;
  margin: 0;
}
.wrap .info {
  width: 286px;
  height: 120px;
  border-radius: 5px;
  border-bottom: 2px solid #ccc;
  border-right: 1px solid #ccc;
  overflow: hidden;
  background: #fff;
}
.wrap .info:nth-child(1) {
  border: 0;
  box-shadow: 0px 1px 2px #888;
}
.info .title {
  padding: 5px 0 0 10px;
  height: 30px;
  background: #eee;
  border-bottom: 1px solid #ddd;
  font-size: 18px;
  font-weight: bold;
}
.info .close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: #888;
  width: 17px;
  height: 17px;
  background: url("https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/overlay_close.png");
}
.info .close:hover {
  cursor: pointer;
}
.info .body {
  position: relative;
  overflow: hidden;
}
.info .desc {
  position: relative;
  margin: 5px 0 0 80px;
  height: 80px;
}
.desc .ellipsis {
  /* overflow: hidden;
  text-overflow: pre-line; */
  white-space: initial;
}
.desc .jibun {
  font-size: 11px;
  color: #888;
  margin-top: -2px;
}
.info .img {
  position: absolute;
  top: 5px;
  left: 5px;
  width: 70px;
  /* border: 1px solid #ddd; */
  color: #888;
  /* overflow: hidden; */
}

.img img {
  object-fit: cover;
  width: 70px;
}

.info:after {
  content: "";
  position: absolute;
  margin-left: -12px;
  left: 50%;
  bottom: 0;
  width: 22px;
  height: 12px;
  background: url("https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/vertex_white.png");
}
.info .link {
  color: #5085bb;
}

.marker-image {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
